import { graphql } from 'gatsby';
import * as React from 'react';
import DefaultGrid from '../../components/grids/DefaultGrid';
import PageHeader from '../../components/sections/PageHeader';
import CategoryTeaser from '../../components/teaser/CategoryTeaser';
import makePath from '../../helpers/makePath';
import useCategoryTree from '../../hooks/useCategoryTree';

const Category = ({ data }) => {
  const rootCategory = useCategoryTree(
    data.strapiCategory.locale,
    data.strapiCategory.strapi_id
  )[0];

  const items = [...rootCategory.children, ...rootCategory.strapiPages].sort(
    (a, b) => a.title.localeCompare(b.title)
  );

  return (
    <>
      <PageHeader {...data.strapiCategory.header} />
      <div className="container mx-auto lg:px-8 px-4 py-16">
        <DefaultGrid>
          {items.map((page) => (
            <CategoryTeaser
              key={page.strapi_id}
              to={makePath(page.locale, page.slug)}
              {...page}
            />
          ))}
        </DefaultGrid>
      </div>
    </>
  );
};

export const query = graphql`
  query ($id: String) {
    strapiCategory(id: { eq: $id }) {
      strapi_id
      title
      excerpt
      locale
      slug
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      meta {
        ...SiteMeta
      }
      header {
        ...PageHeader
      }
    }
  }
`;

export default Category;
