import React from "react";
import TeaserCard from "../cards/TeaserCard";

export type CategoryTeaserProps = {
    to: string,
    title: string,
    excerpt: string
};

export default function CategoryTeaser({
    to, 
    title, 
    excerpt
}: CategoryTeaserProps) {
    return <TeaserCard link={to} title={title} description={excerpt} />;
}